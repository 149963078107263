jQuery(function($) {
  $('.song').click(function() {
    const audio = $(this).children('audio')
    const dontPlay = $(this).parent().parent().parent().siblings().children().children().children('.song');
    const dontPlayAudio = dontPlay.children('audio');

    dontPlay.removeClass('active');
    dontPlayAudio.trigger('pause');

    $(this).toggleClass('active');

    if($(this).hasClass('active')) {
      audio.trigger('play');

    } else {
      audio.trigger('pause');
    }
  })
})