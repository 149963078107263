jQuery(document).ready(function($) {
  require('./components/mobile-header.js');
  require('./components/header-scroll.js');
  require('./components/accordion.js');
  require('./components/slider.js');
  require('./components/team-popup.js');
  require('./components/owl.js');
  require('./components/audio-play.js');
  require('./components/parallax-scroll.js');

  loadMorePosts.init();


  // Parallax wrapper
  $(window).scroll(function() {
    let wScroll = $(this).scrollTop();

    $(".parallax-wrapper").css({
      transform: "translateY(-" + wScroll / 100 + "%)"
    })
  });
});


// Audio Duration
const audio = document.querySelectorAll('.aud');
const audioText = document.querySelectorAll('.duration');
const audioLength = audio.length;

for(let i = 0; i < audioLength; i++) {
  let audioNEW = new Audio();
  audioNEW.src = audio[i].src;

  audioNEW.addEventListener('loadedmetadata', function() {
    const duration = audioNEW.duration;
    const durationRound = Math.floor(duration);

    if(durationRound >= 60) {
      const timeformat = durationRound / 60;
      audioText[i].textContent = `${timeformat} MINS`;

    } else {
      audioText[i].textContent = `${durationRound} SEC`;
    }
  });
};


// Header Search
jQuery('.slide-open').click(function() {
  var form = jQuery('.header-search-bar')
  var inputVal = form.find('input').val()

  if(inputVal) {
    form.submit();
  } else {
    form.toggleClass('active');
  }
});


// Home Banner
current = window.location.pathname;

if(current === '/') {
  const bannerSVG = document.querySelector('.banner-svg');
  const section = document.querySelector('.banner-svg-container');

  const homeBannerScrollText = () => {
    let scrollDistance = -(section.getBoundingClientRect().top);
    let progressPercentage = (scrollDistance / (section.getBoundingClientRect().height - document.documentElement.clientHeight)) * 100;
    let val = Math.floor(progressPercentage);

    let val2 = val - 140;
    let val3 = -val2;

    bannerSVG.style.opacity = val3 + '%';
  };

  window.addEventListener('load', homeBannerScrollText);
  window.addEventListener('scroll', homeBannerScrollText);
}


// Load More Posts
var loadMorePosts = {
	init: function() {
    jQuery('#load-more').click(function(){
      loadMorePosts.loadMore();
    });
	},

	loadMore: function() {
		var loadContainer = jQuery('#load-container');
		var postCount = jQuery('#load-container .post-thumbnail').length;
		var totalPosts = loadContainer.data('total');

		if(postCount >= totalPosts) {
			return;
		}

		var data = {
      action: 'load_more_posts',
      post_count: postCount,
      post_search: posts.s,
      current_taxonomy: posts.current_taxonomy,
      current_term: posts.current_term,
      current_child_term: posts.current_child_term,
		};

		jQuery.ajax({
			url: posts.ajaxurl,
			type: "POST",
			data: data,
			beforeSend: function () {

				// Request for posts

				jQuery('#load-more').addClass('loading');
			},
			complete: function () {

				// Request for posts received / response is being sent back to us

				jQuery('#load-more').removeClass('loading');
			},
			success: function(data) {

				// We get posts back / successful response

				if(data) {
          loadContainer.append(data);
				}

				postCount = jQuery('#load-container .post-thumbnail').length;

				if(postCount >= totalPosts) {
          jQuery('#load-more').replaceWith('<a class="load-button no-posts" href="#load-container"></a>');
				}

				return;
			}
		});
	}
}